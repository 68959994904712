import React, { useEffect } from 'react'
import { motion, HTMLMotionProps, useAnimation  } from 'framer-motion'

type PageTransitionProps = HTMLMotionProps<'div'>

export default function PageTransition({ children }: PageTransitionProps) {
	const Transparent = { opacity: 0 }
    const Solid = { opacity: 1 }

	const transition = { duration: 0.6, ease: 'easeInOut' }

	const controls = useAnimation();

	useEffect(() => {
		controls.start('exit').then(() => {
		window.scrollTo(0, 0);
		});
	}, [controls]);

	return (
		<motion.div
			initial={Transparent}
			animate={Solid}
			exit={Transparent}
			transition={transition}
		>
			{children}
		</motion.div>
	)
}
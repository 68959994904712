import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import classes from './button.module.scss';
import Subtract from '@/assets/images/subtract.svg';

export type ButtonProps = {
    link: string,
    title: string,
    style: "primary" | "secondary" | "internal",
    column?: number,
    className?: string,
    mobile_right?: boolean,
    target?: string,
    serviceButton?: boolean
}

const Button = (props: ButtonProps) => {

    const _classes = cx(classes['button'], {
        [classes[`${props.style}`]]: props.style,
        [classes[`column-${props.column}`]]: props.column,
        [classes[`align-right`]]: props.mobile_right === true,
        [classes['service-button']]: props.serviceButton
    });

    return (
        <div className={_classes}>
            <Link scroll={false} target={props.target ? props.target : ''} href={props.link}>
                {props.title}
                <Subtract />
            </Link>
        </div>
    )
}

export default Button;
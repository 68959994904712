import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import classes from "./strategy.module.scss";

export type StrategyProps = {
    isActive: boolean,
    isPrevActive: boolean,
    duration: number,
    feature?: boolean,
    small?: boolean
}

export default function Performance(props: StrategyProps) {

    const [animateIn, setAnimateIn] = useState(false);

    useEffect(() => {
        if (props.isActive) {
            const timer = setTimeout(() => {
                setAnimateIn(true);
            }, 1000);
            return () => clearTimeout(timer);
        } else if (props.isPrevActive) {
            setAnimateIn(false);
        }
    }, [props.isActive, props.isPrevActive]);

    const variants = {
        visible: { opacity: 1, pathLength: animateIn ? 1 : 0, strokeWidth: animateIn ? 7 : 0 },
        hidden: { opacity: 0, pathLength: animateIn ? 0 : 0 },
    };

    return (
        <div className={classes['icon'] + " " + (props.feature ? classes['feature'] : '') + " " + (props.small? classes['small'] : '')}>

            <motion.svg viewBox="0 0 290 290">
            <motion.g initial="hidden" animate="visible" variants={variants}>
                {/* Converted Polylines */}
                <motion.path
                    d="M262.9,41.4 L246,24.5 L229.1,41.4"
                    fill="none"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transition={{ duration: props.duration, delay: props.duration * 0.5 }}
                />

                <motion.path
                    d="M246,262c-6.7,0-13-3-17.1-8.3c-3.6-4.6-5.2-10.3-4.5-16c0.7-5.8,3.6-10.9,8.2-14.5c3.9-3,8.5-4.7,13.4-4.7c6.7,0,13,3,17.1,8.3c3.6,4.6,5.2,10.3,4.5,16c-0.7,5.8-3.6,10.9-8.2,14.5C255.6,260.4,250.9,262,246,262z"
                    stroke="#FFFFFF"
                    fill="none"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transition={{ duration: props.duration, delay: props.duration * 0.5 }}
                />

                <motion.line
                    x1="246"
                    y1="218.6"
                    x2="246"
                    y2="27.6"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transition={{ duration: props.duration, delay: props.duration * 0.5 }}
                />

                <motion.path
                    d="M178.6,222c5.6,0,10.9,2.5,14.4,7c6.2,7.9,4.8,19.4-3.1,25.6c-3.2,2.6-7.1,3.9-11.3,3.9c-5.6,0-10.9-2.5-14.4-7c-6.2-7.9-4.8-19.4,3.1-25.6C170.6,223.4,174.5,222,178.6,222"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transition={{ duration: props.duration, delay: props.duration * 0.2 }}
                />

                <motion.line
                    x1="178.7"
                    y1="218.6"
                    x2="178.7"
                    y2="133.7"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transition={{ duration: props.duration, delay: props.duration * 0.2 }}
                />

                <motion.path
                    d="M178.6,106.4c3.6,0,6.9,1.6,9.1,4.4c3.9,5,3.1,12.3-1.9,16.2c-2.1,1.6-4.5,2.5-7.1,2.5c-3.6,0-6.9-1.6-9.1-4.4c-3.9-5-3.1-12.3,1.9-16.2C173.6,107.3,176,106.4,178.6,106.4"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: props.duration, delay: props.duration * 0.2 }}
                />

                <motion.path
                    d="M111.3,222c5.6,0,10.9,2.5,14.4,7c6.2,7.9,4.8,19.4-3.1,25.6c-3.2,2.6-7.1,3.9-11.3,3.9c-5.6,0-10.9-2.5-14.4-7c-6.2-7.9-4.8-19.4,3.1-25.6C103.3,223.4,107.2,222,111.3,222"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: props.duration, delay: props.duration * 0.25 }}
                />

                <motion.line
                    x1="111.3"
                    y1="218.6"
                    x2="111.3"
                    y2="98.5"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: props.duration, delay: props.duration * 0.25 }}
                />

                <motion.path
                    d="M111.3,70.6c3.6,0,6.9,1.6,9.1,4.4c1.9,2.4,2.8,5.4,2.4,8.5c-0.4,3.1-1.9,5.8-4.3,7.7c-2.1,1.6-4.5,2.5-7.1,2.5c-3.6,0-6.9-1.6-9.1-4.4c-1.9-2.4-2.8-5.4-2.4-8.5c0.4-3.1,1.9-5.8,4.3-7.7C106.2,71.4,108.7,70.6,111.3,70.6"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    fill="none"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: props.duration, delay: props.duration * 0.25 }}
                />

                <motion.path
                    d="M44,222c5.6,0,10.9,2.5,14.4,7c6.2,7.9,4.8,19.4-3.1,25.6c-3.2,2.6-7.1,3.9-11.3,3.9c-5.6,0-10.9-2.5-14.4-7c-6.2-7.9-4.8-19.4,3.1-25.6C36,223.4,39.9,222,44,222"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    fill="none"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: props.duration, delay: 0 }}
                />

                <motion.line
                    x1="44"
                    y1="218.6"
                    x2="44"
                    y2="155"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: props.duration, delay: 0 }}
                />

                <motion.path
                    d="M44,127.7c3.6,0,6.9,1.6,9.1,4.4c3.9,5,3.1,12.3-1.9,16.2c-2.1,1.6-4.5,2.5-7.1,2.5c-3.6,0-6.9-1.6-9.1-4.4c-3.9-5-3.1-12.3,1.9-16.2C38.9,128.6,41.4,127.7,44,127.7"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: props.duration, delay: 0 }}
                />
            </motion.g>
        </motion.svg>
        </div>
    )
}

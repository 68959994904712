import React, { ReactElement } from 'react';
import cx from 'classnames';
import Button from '../button/button';
import classes from './contact.module.scss';
import LogoLarge from '@/assets/images/logo-large.svg';

export type ContactProps = {
    children?: ReactElement,
    className?: string,
    background?: { colour: string },
    text?: string,
    type?: 'h2' | 'h3' | 'h4' | 'h5' | 'p',
    column?: number,
    style?: React.CSSProperties,
}

const Text = (props: ContactProps) => {

    const _classes = cx(classes['wrapper'], {
        [classes[`bg-${props.background?.colour}`]]: props.background?.colour,
    }, props.className);

    return (
        <div className={_classes}>
            <div className={classes['contact-content']}>
                <h2 className={classes['h4']}>Contact us today</h2>
                <p>Together, we will develop a winning game plan that positions your business at the forefront, outperforming the competition and achieving your goals. </p>
                <Button mobile_right className={classes['button']} link="/contact" title="Contact Us" style="secondary" />
            </div>
            <div className={classes['bg-logo']}>
                <LogoLarge />
            </div>
        </div>
    )
}

export default Text;
import React, { ReactNode } from 'react';
import cx from 'classnames';
import classes from './text.module.scss';

export type TextProps = {
    children?: ReactNode,
    className?: string,
    background?: { colour: string },
    text?: ReactNode,
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p',
    size?: 'small' | 'medium' | 'large' | 'full',
    padding?: 'small' | 'medium' | 'large',
    column: number,
    style?: React.CSSProperties,
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
}

const Text = (props: TextProps) => {

    const _classes = cx(classes['text'], {
        [classes[`column-${props.column}`]]: props.column,
        [classes[`size-${props.size}`]]: props.size,
        [classes[`padding-${props.padding}`]]: props.padding

    }, props.className);

    const content = (
        <>
          {props.children}
        </>
      );

    return (
        <div className={_classes}>
            {props.type === 'h1' ?
            <h1 className={props.as ? classes[props.as] : ''}>{content}</h1>
            :
            props.type === 'h2' ?
            <h2 className={props.as ? classes[props.as] : ''}>{content}</h2>
            :
            props.type === 'h3' ?
            <h3 className={props.as ? classes[props.as] : ''}>{content}</h3>
            :
            props.type === 'h4' ?
            <h4 className={props.as ? classes[props.as] : ''}>{content}</h4>
            :
            props.type === 'h5' ?
            <h5 className={props.as ? classes[props.as] : ''}>{content}</h5>
            :
            props.type === 'p' ?
            <p className={props.as ? classes[props.as] : ''}>{content}</p>
            :
            null
            }
        </div>
    )
}

export default Text;
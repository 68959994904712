import React, { ReactElement } from 'react';
import cx from 'classnames';
import classes from './content-area.module.scss';

export type ContentAreaProps = {
    children?: ReactElement,
    className?: string,
    background?: { colour: string },
    text?: string,
    type?: 'h2' | 'h3' | 'h4' | 'h5' | 'p',
    column?: number,
    style?: React.CSSProperties,
    hero?: boolean
}

const ContentArea = (props: ContentAreaProps) => {

    const _classes = cx(classes['content-area'], {
        [classes[`bg-${props.background?.colour}`]]: props.background?.colour,
        [classes['is-hero']]: props.hero
    }, props.className);

    return (
        <section className={_classes}>
            {props.children}
        </section>
    )
}

export default ContentArea;